.loaderWrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalLoader {
  z-index: 1000000 !important;
}
