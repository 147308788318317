$olive: #7d8035;
$lightOlive: #707428;
$green: #008539;
$orange: #e87325;
$lightOrange: #e87a25;
$blue: #2178cf;
$red: #e03131;
$darkBlack: #222222;
$slightDarkBlack: #222;
$darkGrey: #3d3d3d;
$lightGrey: #555555;
$yellow: #ff9212;
$lightYellow: #f3f5cd;
$white: #ffffff;
$bgGrey: #f8f8f8;
$black100: #0a0a0a;
$bgDarkGrey: #f2f2f2;
$defaultBorderRadius: 4px;
$lighterGrey: #999999;
$lightestGrey: #d5d5d5;
$borderColor: #dcdcdc;
$dullCharcoal: #444;
$black: #000000;
$darkGray: #555;
