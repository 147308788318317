@import "/styles/themes/colors";
.bannerContainer {
  width: 100%;
  display: block;
  position: relative;

  a {
    position: relative;
    display: block;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .crossIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    z-index: 10;
    color: rgb(75, 69, 69);
    padding: 5px;
    cursor: pointer;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 2px;
      background-color: $darkGray;
      transform-origin: center;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
