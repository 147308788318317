@import "./themes/theme";
@import "./themes/colors";
@import "../components/common/styles/microSite.scss";

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url(/assets/fonts/Hgo13k-tfSpn0qi1SFdUfbO3LdcAZYWl9Si6vvxL-qU.woff) format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"), local("sans-serif"),
    url(/assets/fonts/oMMgfZMQthOryQo9n22dcuvvDin1pK8aKteLpeZ5c0A.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Roboto Medium"), local("Roboto-Medium"), local("sans-serif-medium"),
    url(/assets/fonts/RxZJdnzeo3R5zSexge8UUZBw1xU1rKptJj_0jans920.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Condensed Bold"), local("RobotoCondensed-Bold"),
    url(/assets/fonts/b9QBgL0iMZfDSpmcXcE8nHm2hrkyco9jQ3NKMXpgOBg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(/assets/css/material-fonts.woff2) format("woff2");
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Roboto, sans-serif;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  max-width: 25px;
}

.container {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-mobile {
  padding-top: 30px;
}
.mobile-main-container.MuiContainer-root,
.mobile-main-container .MuiContainer-root {
  padding: 0px !important;
}
.MuiContainer-maxWidthLg {
  max-width: 100% !important;
}

.no-scroll {
  overflow: hidden !important;
}
.pac-container,
.pac-item {
  display: block;
  z-index: 9999;
}
.pac-item:first-child {
  background-color: #ebf2fe;
}
.pac-item:first-child .pac-icon-marker {
  background-position: -18px -161px;
}
ul {
  list-style-type: none;
  padding-left: 0px;
  margin-top: 0px;
}
a {
  text-decoration: none;
  color: $darkBlack;
}
.bottomIcons {
  height: 3.125rem;
}
figure {
  margin: 0;
}
html {
  min-height: 100%;
}

// we used third party library for calender that's why i used important for make UI changes in that component

.react-calendar__tile--now {
  background: none !important;
}

.react-calendar__tile {
  abbr {
    font-size: 20px;
  }
}

.react-calendar__month-view__weekdays__weekday {
  position: relative;
  abbr {
    text-decoration: none !important;
    color: #787878;
    font-size: 0px;
    font-weight: 600;
    width: 2px !important;
    overflow: hidden;
    border-radius: 50%;
  }
  &::before {
    position: absolute;
    content: "";
    font-size: 16px;
    left: 0;
    right: 0;
    margin: auto;
    color: #787878;
    font-weight: none;
  }
  &:first-child {
    &::before {
      content: "M";
    }
  }
  &:nth-child(2) {
    &::before {
      content: "T";
    }
  }
  &:nth-child(3) {
    &::before {
      content: "W";
    }
  }
  &:nth-child(4) {
    &::before {
      content: "T";
    }
  }
  &:nth-child(5) {
    &::before {
      content: "F";
    }
  }
  &:nth-child(6) {
    &::before {
      content: "S";
    }
  }
  &:nth-child(7) {
    &::before {
      content: "S";
    }
  }
}

// we used third party library for calender that's why i used important for make UI changes in that component
.MuiDrawer-paperAnchorBottom {
  max-height: 80% !important;
  border-radius: 6px 6px 0 0;
}

.react-calendar__month-view__days__day--weekend {
  .flex-row {
    display: flex;
    flex-direction: row;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .currency-INR:before {
    content: "\20B9";
    margin-right: 2px;
  }

  .text-upper {
    text-transform: uppercase;
  }
  .text-bold {
    font-weight: bold !important;
  }

  .red {
    color: #e53333 !important;
  }
  .green {
    color: #00a948 !important;
  }

  .mgn-top-5 {
    margin-top: 5px !important;
  }
  .mgn-top-50 {
    margin-top: 50px !important;
  }
  .mgn-top-20 {
    margin-top: 20px !important;
  }
  .mgn-top-10 {
    margin-top: 10px !important;
  }
  .mgn-btm-20 {
    margin-bottom: 20px !important;
  }
  .mgn-right-10 {
    margin-right: 10px !important;
  }
  .mgn-left-20 {
    margin-left: 20px !important;
  }
  .mgn-left-50 {
    margin-left: 50px !important;
  }

  .no-pdd {
    padding: 0px !important;
  }
  .no-mgn {
    margin: 0px !important;
  }

  .uni-flex {
    display: flex;
    flex: 1;
  }

  .bg-white {
    background-color: #fff;
  }
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  width: 120px;
}

/* delivery */

.MuiFormControl-root.deliver-input {
  border: 0;
  input {
    display: block;
    padding: 8px 13% 8px 35px;
    border-radius: 4px;
    line-height: 26px;
    font-size: 20px;
    margin: 0;
    transition: all 0.25s ease-in-out;
    height: auto;
    width: 100%;
    font-weight: 400;
    border: 1px solid #666;
    color: #000;
    background: #f8f8f8;
  }
  fieldset {
    display: none;
  }
}
.deliver-input.animate {
  input {
    border: 1px solid $yellow;
    box-shadow: 0 0 5px $yellow;
    animation: c ease-in-out 1.5s infinite;
    background: #fff;
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
}

.deliver-section-input-container {
  position: relative;
  margin-top: 11px;
  .pin-icon {
    position: absolute;
    left: 10px;
    top: 12px;
    width: 20px;
    height: 20px;
    z-index: 2;
    svg {
      color: #666;
      width: 100%;
      height: 100%;
    }
    &.red {
      svg {
        color: $red;
      }
    }
  }
  .close-icon {
    color: $lightGrey;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .right-arrow-icon {
    position: absolute;
    right: 14px;
    top: 19px;
    width: 15px;
    height: 15px;
    svg {
      width: 100%;
      height: 100%;
      color: #666;
    }
  }
  &.mt6 {
    margin-top: 6px;
  }
}

.required-err-msg {
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.43;
  text-align: left;
  color: $red;
  margin: 0;
}

.required-err-msg.slide-in {
  animation: slideIn 1.5s;
}

@keyframes slideIn {
  0% {
    -webkit-transform: translate(30px);
  }

  20% {
    -webkit-transform: translate(-30px);
  }

  40% {
    -webkit-transform: translate(15px);
  }

  60% {
    -webkit-transform: translate(-15px);
  }

  80% {
    -webkit-transform: translate(8px);
  }

  to {
    -webkit-transform: translate(0);
  }
}

.mt20 {
  margin-top: 20px;
}
.disableSelectInput {
  opacity: 0.5;
  pointer-events: none;
  margin-bottom: 6px;
}

.selected-pin {
  font-weight: bold;
  font-size: 13px;
  color: #555;
  text-align: right;
  display: block;
  padding-top: 10px;
  position: absolute;
  top: 36px;
  right: 0;
}

button {
  &.form-button-secondary,
  &.form-button-disabled,
  &.form-button-primary {
    position: relative;
    width: 100%;
    background: $orange;
    border: 0;
    color: $white;
    max-height: 52px;
    min-height: 50px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    text-transform: uppercase;
    outline: none;
    box-shadow: none;
    &.MuiButton-contained:hover {
      box-shadow: none;
      background-color: $orange;
    }

    &.progress-bar {
      background: #dd771f none repeat scroll 0 0;
      background-color: #dd771f;
      background-image: linear-gradient(
        -45deg,
        hsla(0, 0%, 100%, 0.5) 25%,
        transparent 0,
        transparent 50%,
        hsla(0, 0%, 100%, 0.5) 0,
        hsla(0, 0%, 100%, 0.5) 75%,
        transparent 0,
        transparent
      );
      background-size: 40px 40px;
      width: 100%;
      opacity: 1;
      pointer-events: none;
      animation-name: progress-bar-stripes;
      animation-duration: 2s;
      animation-timing-function: linear;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-direction: normal;
      animation-fill-mode: none;
      animation-play-state: running;
      &.MuiButton-contained:hover {
        box-shadow: none;
        background-color: #dd771f;
      }
    }
  }
  &.form-button-secondary {
    background: #fff;
    color: #333;
    &.MuiButton-contained:hover,
    &:focus {
      background: #fff;
      color: #333;
      border: 0;
      outline: 0;
    }
  }
  &.form-button-disabled {
    background: #d3d3d3;
    color: #fff;
    pointer-events: none;
    &.MuiButton-contained:hover,
    &:focus {
      background: #fff;
      color: #555;
      border: 0;
      outline: 0;
    }
  }
  &.mt40 {
    margin-top: 40px;
  }
  &.mt20 {
    margin-top: 20px;
  }
  &.MuiButton-root {
    &.Mui-disabled {
      color: rgba(255, 255, 255, 0.8);
      box-shadow: none;
      background-color: rgba(243, 127, 10, 0.5);
    }
  }
  &.disable-form-continue-button {
    color: hsla(0, 0%, 100%, 0.8);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: 0.6;
    &.MuiButton-contained:hover {
      -webkit-box-shadow: none;
      box-shadow: none;
      opacity: 0.5;
    }
  }
}

.MuiCircularProgress-colorPrimary {
  color: #009212 !important;
}
.toast-fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.tnc-text {
  padding: 10px;
  ul {
    height: 100%;
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: scroll;
    :last-child {
      margin-bottom: 5px;
    }
    li {
      list-style: disc inside none;
      padding-left: 2em;
      text-indent: -1.4em;
      margin-right: 5px;
      line-height: 1.5rem;
    }
  }
}

::selection{
	color: #fff;
	background: #009d43;
}
::-moz-selection{
	color: #fff;
	background: #009d43;
}
::-webkit-selection{
	color: #fff;
	background: #009d43;
}
::-ms-selection{
	color: #fff;
	background: #009d43;
}
