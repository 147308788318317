@import "../.././../../../../styles/themes/colors";

.account-dropdown-header {
  align-items: baseline;
  padding: 24px 12px;
  background: $darkBlack;
  color: #999;
  border-radius: 4px 4px 0 0;
  & > div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 85%;
    display: inline-block;
    vertical-align: middle;

    & > span {
      vertical-align: top;
      margin-right: 5px;
    }
  }
  & a {
    color: #fff;
    font-size: 14px;
  }

  .account-user-name {
    color: $white;
  }
}
.account-dropdown-container {
  max-height: 480px;
}
.account-dropdown-list {
  padding: 0;
  & > a {
    padding: 15px 0px 15px 15px;
    display: block;
    line-height: 1;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    & i {
      color: rgb(102, 102, 102);
      margin-right: 10px;
      font-size: 16px;
    }
  }
}
.user-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60px;
  min-width: 50px;
  vertical-align: middle;
  display: inline-block;
}
.menu-width {
  width: max-content;
  min-width: 234px;
}
.fnpCashSpan{
  display: flex;
  gap : 5px;
}
.fnpNewBadge {
  margin: 0 0 4px 5px;
}
