@import "../../../../styles/themes/colors";
.plp-sec {
  background: #f2f2f2;
  :global {
    .MuiContainer-maxWidthMd {
      max-width: 1024px;
    }
  }
}

.full-height {
  height: 100%;
}

.product-list {
  height: 100%;
  border-right: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  border-top: 1px solid #dcdcdc;
  background: #fff;
}

.product-rating {
  color: #ff9212;
  font-size: 0.8rem;
  font-weight: bold;
  padding-right: 3px;
}

.product-rnr {
  :global {
    .MuiRating-root {
      color: #ff9212;
      font-size: 0.75rem;
      vertical-align: middle;
      bottom: 1px;
    }
  }
}

.product-reviews {
  font-size: 0.8rem;
  color: #333333;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 10px;
}

.product-rnr {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiRating-decimal {
    position: relative;
    width: 0.688rem;
  }
}

.loader {
  padding-top: 16px;
  text-align: center;
}
.infiniteLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
}
.m-plp-breadcrumb-wrapper {
  padding: 0 10px;
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em,
      -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em,
      -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0,
      -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0,
      -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0,
      -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0,
      -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em,
      -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em,
      -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0,
      -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0,
      -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0,
      -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0,
      -2em -2em 0 0.2em;
  }
}

.capsulesWithText,
.capsulesWithImages {
  margin-bottom: 20px;
  display: flex;
  gap: 12px;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #f8f8f8 transparent;

  .capsule {
    display: flex;
    justify-content: center;
    cursor: pointer;
    text-wrap: nowrap;

    .textCapsule {
      color: #555;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      background-color: $white;
      padding: 12px;
      border-radius: 31px;
      border: 1px solid #d5d5d5;
    }

    .imageCapsule {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      div {
        text-wrap: wrap;
        text-align: center;
        display: -webkit-box; /* Creates a flex container with webkit box model */
        -webkit-line-clamp: 2; /* Limits the text to 2 lines */
        -webkit-box-orient: vertical; /* Specifies the box orientation as vertical */
        overflow: hidden; /* Ensures content beyond 2 lines is hidden */
        text-overflow: ellipsis;
        color: #191a0b;
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
      }

      img {
        width: 65px;
        height: 63.194px;
        display: inline-block;
        padding-top: 5px;
        border-radius: 16px;
      }
    }
  }
}
.banner-details {
  :global {
    .cs-listing {
      .row {
        max-width: 62.5rem;
        margin: 0;
      }
      ul {
        padding: 0;
        li:nth-child(even) {
          padding-left: 5px;
          padding-right: 0;
        }
        li:nth-child(odd) {
          padding-right: 5px;
          padding-left: 0;
        }
        li {
          display: flex;
          float: left;
          width: 50%;
          text-align: center;
          position: relative;
          transition: all ease-in-out 0.25s;
          cursor: pointer;
          justify-content: space-between;
          box-sizing: border-box;
          margin-left: 0px;
          margin-top: 10px;
          margin-bottom: 10px;
          padding: 0 5px;
          a {
            width: 100%;
            figure {
              margin: 0;
              text-align: center;
              position: relative;
              padding-top: 72.91%;
              img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 4px;
                padding: 5px;
                transition: all ease-in-out 0.25s;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
              }
            }
          }
        }
      }

      a {
        width: 100%;
        color: #31afac;
        text-decoration: none;
      }
    }
    .quickLinks {
      display: none;
    }
  }
}
.category-header {
  background: #fff;
  padding: 10px 10px 0 10px;
}
.no-data {
  background-color: #fff;
  text-align: center;
  width: 100%;
  height: 50px;
  padding-top: 10px;
}
.rating-sec {
  min-height: 20px;
  width: 94%;
  margin: 0.4rem 3% 0 3%;
}
.infoContainer {
  background-color: $white;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
}

@media only screen and (max-width: 40em) {
  .category-header.category-page .cs-listing ul li:nth-child(odd) {
    padding-right: 5px;
    padding-left: 0;
  }
}

@media only screen and (max-width: 40em) {
  .category-header.category-page .cs-listing ul li:nth-child(even) {
    padding-left: 5px;
    padding-right: 0;
  }
}

@media only screen and (min-width: 1366px) {
  .category-header {
    .banner-details {
      padding: 0;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    text-align: center;
    font-weight: 300;
    margin: 0;
    font-size: 16px;
    color: #222;
    line-height: 30px;
    padding: 10px;

    :global {
      .cs-listing {
        ul {
          margin: 0;
          padding: 0;
          li:nth-child(even) {
            padding-right: 5px;
            padding-left: 5px;
          }
          li:nth-child(odd) {
            padding-right: 5px;
            padding-left: 5px;
          }
        }
      }
    }
  }
}

@media (max-width: 360px) and (min-width: 319px) {
  .product-rnr .product-rating,
  .product-reviews {
    font-size: 0.78rem;
  }
}

@media (max-width: 320px) {
  .product-rnr .product-rating,
  .product-reviews {
    margin-top: 0;
  }
}

@media (min-width: 960px) {
  .plp-sec .MuiContainer-maxWidthMd {
    width: 100%;
    max-width: initial;
  }
}

.toast {
  bottom: 50px !important;
}
