.wrapper {
  margin: 0 16px;

  .btn {
    margin: 0;
    border: none;
    outline: none;
    cursor: pointer;
    line-height: 1;
    padding: 14px;
    border-radius: 5px;
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    }
  }

  .btn > span {
    font-size: 12px;
    font-weight: 500;
  }
}
