@import "/styles/themes/colors";

.box {
  display: flex;
  align-items: start;
  justify-content: flex-start;
  width: 100%;
  height: 90px;
  margin-top: 12px;
  margin-bottom: 3px;
  flex-shrink: 0;
  background: #fff;
  border-radius: 4px;
  padding: 12px 0 12px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.desktopPaymentStyle {
  width: 86%;
  display: inline-flex;
  align-items: start;
  justify-content: flex-start;
  margin-top: 12px;
  margin-bottom: 3px;
  margin-left: 58px;
  padding: 12px 20px;
  border-radius: 4px;
  background: rgba(0, 133, 57, 0.1);
}
.checkBoxStyle {
  padding: 0;
}
.textWallet {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 12px;
  margin-left: 2px;
}
.textLabel {
  margin-left: -5px;
}
.text {
  color: #222;
  font-family: Roboto;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 190%;
}
.colorPrimary {
  color: #7d8035 !important;
  font-family: Material-Design-Iconic-Font;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.subHeading {
  color: #555;
  font-family: Roboto;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: capitalize;
}
.fnpcashContainer {
  width: 100%;
}
.usableBalance {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 5px 0 11px -3px;
  position: relative;
  p {
    @extend .subHeading;
    display: inline-block;
    text-transform: none;
  }
  .tooltipText {
    visibility: hidden;
    width: 306px;
    text-align: center;
    border-radius: 6px;
    padding: 18px 20px 15px 16px;
    position: absolute;
    z-index: 11;
    top: 230%;
    left: -5%;
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 4px;
    background: $white;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    &.visible {
      visibility: visible;
      opacity: 1;
    }
    header {
      color: $dullCharcoal;
      font-size: 14px;
      text-align: start;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      margin-bottom: 11px;
    }
    .tooltipBody {
      margin-left: 14px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      text-align: left;
      li {
        color: $lightGrey;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .iBtn {
    cursor: pointer;
    width: 12px;
    height: 12px;
  }

  .iBtn:hover ~ .tooltipText {
    visibility: visible;
    opacity: 1;
    position: absolute;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 10%;
      width: 0;
      height: 0;
      border: 16px solid transparent;
      border-bottom-color: $white;
      border-top: 0;
      margin-left: -10px;
      margin-top: -10px;
    }
  }
}
.usedWallet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.usedText {
  color: #555;
  text-align: right;
  font-family: Roboto;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.amountEntry {
  color: #555;
  font-family: Roboto;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 145.187%;
  text-transform: capitalize;
}
.checkoutDesktopStyle {
  margin: 8px 4px 17px 0px;
}
.checkoutMobileStyle {
  margin: 12px 0;
}
.cartWalletStyle {
  position: relative;
  margin: 5px 0 70px 0;
}
.paymentWalletStyle {
  padding: 15px;
  .childWalletStyle {
    margin: 3px 0 0 0;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
}
.heading2 {
  color: #222;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.disabledCurrency {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  -webkit-animation: fadeInOut 4s;
  animation: fadeInOut 4s;
  max-width: 300px;
  width: 266px;
  white-space: nowrap;
  padding: 7px 13px;
  gap: 10px;
  border-radius: 4px;
  opacity: 0.9;
  background: $darkBlack;
  color: $white;
  font-size: 13px;
  font-weight: 400;
  z-index: 1;
}
