@import "../../../styles/themes/colors";

header {
  position: static;
  float: none;
}
.for-desktop {
  background-color: #ffffff;
}
.content-container {
  max-width: 100%;
  display: inline-block;
  width: 100%;
  img {
    max-width: 100%;
  }
  .columns h2,
  .categorydescp .main-wrapper h2,
  .storeProducts h2,
  .related-pages .category-title {
    color: #59b5d7;
    font-size: 18.8px;
    font-weight: 600;
  }
  .for-mobile .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .row::before,
  .row::after {
    display: table;
    content: " ";
  }
  .row::after {
    clear: both;
  }
  .navigation {
    margin: 0;
    nav ol li,
    p {
      font-size: 14px;
      line-height: 1.7;
    }
  }
  .main-cat p,
  .contest-content p,
  .togel-block p,
  .content-sec p,
  .content-area p,
  .flower-content p,
  #retailoutlets p {
    margin-bottom: 15px;
  }
  .left-section li {
    list-style: none;
  }
  .main-cat a,
  .flower-tabbings .main-container a,
  .togel-block a,
  .content-sec p a,
  .content-design a,
  .blogs a,
  .footerlinks a,
  #retailoutlets p a {
    color: $blue;
  }
  .main-container figure {
    margin: 0 !important;
    text-align: center;
    position: relative;
  }
  .country_list {
    height: 482px;
    padding-bottom: 60px;
  }
  p {
    text-align: justify;
    color: $lightGrey;
    a {
      color: $blue;
    }
  }
  .category p,
  .banner-content p,
  .sbi-content .mobile-btn,
  .occassions ul {
    text-align: center;
  }
  .instructions p,
  .coupons-page-contents p {
    font-size: 13px;
  }
  .see-more span,
  .annual-retun a {
    color: $blue;
  }
  .allTabs .mobile-navigation {
    z-index: 0;
  }
  .categories {
    .all-categories {
      list-style-type: none;
    }
    .review-section {
      text-align: left;
    }
  }
  .categorydescp {
    padding: 25px;
    text-align: justify;
    h2,
    h3 {
      margin-bottom: 10px;
      color: #222;
      font-weight: 500;
      font-size: 18px;
    }
    ul strong {
      font-weight: 500;
    }
    li a,
    td a {
      color: $blue;
    }
    p {
      font-size: 14px;
      color: $lightGrey;
      margin-bottom: 20px;
      line-height: 22px;
      a {
        color: $blue;
      }
    }
  }
  .why-us {
    margin-top: 20px;
  }
  .sec-banners {
    .column-block {
      padding: 0;
    }
    .cs-listing .row::before,
    .cs-listing .row::after {
      display: none;
    }
  }
  .right-sidebar a.all-occasions {
    line-height: normal;
  }
  .sbi-content p,
  .footer-content p {
    font-size: 13px;
    color: $lightGrey;
    line-height: 16px;
    margin-bottom: 15px;
    a {
      color: $blue;
    }
  }
  input.js-open-modal.btn-deals {
    -webkit-appearance: none;
  }
}
.bottomCategories {
  text-align: left;
}
.slick-dots li.slick-active span {
  color: $olive;
  opacity: 0.75;
}
.slick-dots li span {
  border: 0;
  width: 5px;
  height: 5px;
  background: #222;
  opacity: 0.3;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  font-size: 0.1px !important;
  color: transparent;
  line-height: 1;
  float: left;
}
.inputs-sec {
  line-height: 1;
}
#searchform {
  top: 0;
  .arrow-right {
    border: solid $blue;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px 3px 3px 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    vertical-align: -webkit-baseline-middle;
    position: absolute;
    vertical-align: middle;
    top: 5px;
  }
  @media (max-width: 480px) {
    .see-more {
      margin-top: 36%;
    }
  }
}

.homeContainer {
  .occassions .column-block figure {
    text-align: center;
  }

  .occassions {
    width: 100%;
    position: relative;
    z-index: 9;
    clear: both;
  }

  .occassions ul {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
    font-size: 0;
    padding: 0 12px;
  }
}
.maintitle {
  font-size: 26px;
  text-align: center;
  margin: 10px 0 15px 0;
  font-weight: 300;
}

.highlight {
  color: #59b5d7;
}

.slick-slider {
  margin-bottom: 0px;
}

.main .recently-visited {
  clear: both;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

section#footerSEO {
  padding: 0 35px;
}
