@import "../../../../styles/themes/colors";

.empty-cart-container {
  background: $white;
}

.empty-cart {
  padding: 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.empty-cart-details {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 20px;
}
.missing {
  display: block;
  text-align: center;
  font-size: 22px;
  color: $darkBlack;
  font-weight: 300;
  margin: 0;
}
.login_msg {
  display: block;
  font-size: 15px;
  margin-top: 15px;
  color: $lightGrey;
  font-weight: 400;
}
.notLoggedIn {
  margin-top: 20px;
  width: 100%;
}
.LoggedIn {
  width: 100%;
}
.sticky-cart-container {
  bottom: 0;
  position: fixed;
  width: 100vw;
  min-height: 113px;
  background: #ccc;
  z-index: 10;
}
.fNP-Cash-Total {
  color: #008539;
}
.desktop-sticky-cart-container {
  z-index: 99;
}
.sticky-cart-price-info {
  background: $white;
  box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.2);
}
.base-addons-price-container {
  width: 100%;
  line-height: 22px;
  padding: 8px;
  font-weight: 400;
  font-size: 14px;
  color: $darkBlack;
  left: 0;
  display: flex;
  justify-content: space-between;
}
.desktop-left-price-container {
  display: flex;
  .price-content {
    margin-left: 6px;
  }
}
.discount-text-amount-desktop {
  display: flex;
  .price-content {
    margin-left: 25px;
    .inr-symbol {
      font-size: 16px;
    }
  }
}
.discount-text-amount-mobile {
  display: flex;
  justify-content: space-between;
  .price-content {
    .inr-symbol {
      font-size: 16px;
    }
  }
}
.total-price-content {
  direction: rtl;
}
.base-addons-price-container > section {
  flex-grow: 0.3;
}
.price-symbol {
  font-size: 16px;
  font-weight: 500;
  margin-right: 2%;
}
.cart-total {
  max-width: 50%;
}
.total-price {
  color: $darkBlack;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  text-align: right;
}
.cart-total-price-container {
  display: flex;
  justify-content: space-between;
}
.desktop-cart-total-price-container {
  display: flex;
}
.fnp-bold {
  font-weight: 500;
}
.price-info-title {
  font-size: 16px;
  font-weight: 500;
}
.price-info-title-donation {
  font-size: 14px;
}
.desktop-cart-total {
  .cart-total-price-container {
    display: flex;
    justify-content: flex-start;
  }
}

.base-addons-price-container {
  .addons-selected-text {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 74px;
  }
  .addons-selected-text-total {
    flex-grow: 0;
    flex-basis: 74px;
  }
}

/*Retry Payment Component card css starts here*/
.pnc-modules-container {
  padding: 0px 10px;
  // min-height: 100vh;
  background-color: #efefef;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 360px;
}

.cart-empty-text {
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 10px;
  border-bottom: 1px solid #ccc;
  margin: 0;
}
.retry-payment {
  font-size: 1.2rem;
  font-weight: 400;
  padding: 10px;
  margin: 0;
}
.pnc-panel {
  border: 1px solid #d9dcdc;
  border-radius: 4px;
  box-shadow: 0 0 7px #d9dcdf;
  padding: 12px;
  margin-bottom: 15px;
  margin-top: 0;
  background-color: $white;
  display: flex;
  flex-direction: column;
}
.pnc-panel-details {
  display: flex;
  justify-content: flex-start;
}
.pnc-description-panel {
  text-align: left;
  color: #000;
  width: 80%;
}
.pnc-image-panel {
  margin-right: 2.85%;
  height: 60px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
}
.pnc-image-panel img {
  width: 60px;
  height: 60px;
  overflow: hidden;
  font-size: 10px;
}
.statusText {
  font-weight: 400;
  font-size: 1.2rem;
  color: $green;
  margin-bottom: 5px;
  margin: 0px 0px 3px 0px;
}
.delivered-text {
  color: #494a4a;
  font-size: 12px;
  margin: 0px 0px 12px 0px;
  width: 65%;
  line-height: 1.5;
}
.recipient-details {
  font-size: 0.9rem;
  display: inline-block;
  width: 49%;
  margin-bottom: 2.85%;
}
.details {
  color: #000;
  font-size: 0.9rem;
  width: 48%;
  word-break: break-all;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pnc-panel-button {
  margin-top: 10px;
}
/*Retry Payment Component card css ends here*/

/*Retry Payment Skeleton Component card css starts here*/
#my-cart.skeleton {
  height: 100vh;
}
.desktopSkeleton {
  max-height: 360px;
}
.skeleton .input {
  float: left;
  width: 100%;
  height: 44px;
  background: #e2e2e2;
  border-radius: 4px;
  position: relative;
}
#my-cart .productPanel {
  padding: 10px;
  padding-bottom: 20px;
}
.priceDetailsPanel,
.productPanel {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: $white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 12px 0;
  margin-bottom: 15px;
  position: relative;
}
.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: progress-strips;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 800px 104px;
  height: 75px;
  position: relative;
}
@keyframes progress-strips {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
/*Retry Payment Skeleton Component card css ends here*/

/*Cart Skeleton Component card css starts here*/
.skeleton {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 44px);
}

.skeleton .products-s {
  float: left;
  width: 100%;
  margin-top: 15px;
  overflow: hidden;
  white-space: nowrap;
}
.baseProductPanel {
  padding: 10px;
}
.cart-skeleton .baseProductPanel .animated-background {
  height: 200px;
  background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
  animation-duration: 3s;
}
.bg-masker.header-bottom,
.bg-masker.header-top,
.bg-masker.subheader-bottom {
  top: 0;
  left: 75px;
  right: 0;
  height: 5px;
}
.bg-masker {
  background: $white;
  position: absolute;
}
.bg-masker.header-left,
.bg-masker.header-right,
.bg-masker.subheader-left,
.bg-masker.subheader-right {
  top: 0;
  height: 20px;
  left: 75px;
  width: 10px;
}
.bg-masker.header-right,
.bg-masker.subheader-right {
  width: auto;
  left: 300px;
  right: 0;
}
.bg-masker.header-bottom {
  top: 20px;
  height: 10px;
}
.bg-masker.subheader-left,
.bg-masker.subheader-right {
  top: 22px;
  height: 25px;
}
.bg-masker.header-right,
.bg-masker.subheader-right {
  width: auto;
  left: 300px;
  right: 0;
}
.bg-masker.subheader-bottom {
  top: 45px;
  height: 30px;
}
.bg-masker.subheader-bottom.cart-address-top {
  top: 75px;
  height: 25px;
  left: 0;
}
.bg-masker.subheader-bottom.cart-address-bottom {
  left: 0;
  top: 130px;
  height: 20px;
}
#my-cart .base-item-number {
  left: 2px;
  top: 5px;
  z-index: 1;
}
.base-item-number {
  position: absolute;
  border-radius: 0 4px 4px 0;
  left: 2px;
  background: $olive;
  width: 20px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: $white;
  font-size: 16px;
  font-weight: 500;
  top: 5px;
}
#my-cart .cart-buttons {
  bottom: 0;
  position: fixed;
  width: 100vw;
  height: 110px;
  background: #aaa;
  z-index: 10;
}
#my-cart .cart-buttons .cart-pay-info,
#my-cart.skeleton .cart-buttons .cart-pay-info {
  height: 60px;
  background: #ccc;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
}
.cart-buttons .cart-to-pay .animated-background {
  animation-duration: 3s;
  background: linear-gradient(90deg, #eee 4%, #aaa 12%, #aaa 14%);
}
/*Cart Skeleton Component card css ends here*/

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .pnc-modules-container {
    max-height: none;
  }
  .recipient-details {
    width: 48%;
  }
  .details {
    width: 45%;
    margin-top: 1%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .base-addons-price-container {
    padding: 8px 20px;
  }
  .cart-total-price-container {
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .pnc-modules-container {
    max-height: none;
  }
  .recipient-details {
    width: 48%;
  }
  .details {
    width: 45%;
    margin-top: 1%;
  }
}
